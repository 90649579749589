import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-donation-activity',
  templateUrl: './donation-activity.component.html',
  styleUrls: ['./donation-activity.component.scss', '../../app.component.scss']
})
export class DonationActivityComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
