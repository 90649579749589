import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material';

import { ContactDto } from '../../../../../core/dto/contact';
import { ContactService } from '../../core/services/contact.service';
import { ContactModalComponent } from '../contact-modal/contact-modal.component';
import { CharityService } from 'src/app/core/services/charity.service';
import { RegisteredCharityDto } from '../../../../../core/dto/registered-charity';

@Component({
  selector: 'app-contact-info-card',
  templateUrl: './contact-info-card.component.html',
  styleUrls: ['./contact-info-card.component.scss', '../../app.component.scss']
})
export class ContactInfoCardComponent implements OnInit {
  @Input() contact: ContactDto | RegisteredCharityDto;

  constructor(
    public dialog: MatDialog,
    private charityService: CharityService,
    private contactService: ContactService,
    ) { }

  ngOnInit() {
  }

  openEditModal(): void {
    const dialogRef = this.dialog.open(ContactModalComponent, {
      width: '550px',
      data: this.contact
    });

    dialogRef.afterClosed().subscribe(result => {
      // If contact already exists, update
      // Else, save new record
      if (this.contact) {
        const updatedContact = Object.assign(this.contact, result);
        this.contactService.updateContact(updatedContact)
          .then(() => {})
          .catch(error => console.error(error));
      } else {
        const newContact = Object.assign(result, {charity: this.charityService.currentCharity});
        this.contactService.saveContact(newContact)
          .then(responseContact => this.contact = responseContact)
          .catch(error => console.error(error));
      }
    });
  }

}
