import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-tos-modal',
  templateUrl: './tos-modal.component.html',
  styleUrls: ['./tos-modal.component.scss']
})
export class TosModalComponent {

  constructor(
    public dialogRef: MatDialogRef<TosModalComponent>,
  ) { }

  close(): void {
    this.dialogRef.close();
  }

}
