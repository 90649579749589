import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { RegisteredCharityDto } from '../../../../../core/dto/registered-charity';
import { CharityAccountDto } from '../../../../../core/dto/charity-account';
import { CharityDto } from '../../../../../core/dto/charity';

@Injectable({
  providedIn: 'root'
})
export class CharityService {

  // tslint:disable-next-line:variable-name
  private _charity = new BehaviorSubject<RegisteredCharityDto>(null);
  public readonly charity = this._charity.asObservable();

  private _charityId = new BehaviorSubject<string>(null);
  public readonly charityId = this._charity.asObservable();

  constructor(
    private http: HttpClient,
  ) { }

  get currentCharity(): RegisteredCharityDto {
    return this._charity.getValue();
  }

  get currentCharityid(): string {
    return this._charityId.getValue();
  }

  public setCurrentCharity(charity: RegisteredCharityDto): void {
    this._charity.next(charity);
  }

  public setCurrentCharityId(id: string): void {
    this._charityId.next(id);
    this.getCharityById(id).then(charity => this.setCurrentCharity(charity));
  }

  public getCharityById(id: string): Promise<RegisteredCharityDto> {
    const url = environment.backendApi.apiUrl.concat(`/registered-charity/${id}`);
    return this.http.get<RegisteredCharityDto>(url).toPromise();
  }

  public getCharityByTaxId(id: string): Promise<any> {
    const url = environment.backendApi.apiUrl.concat(`/registered-charity/ein/${id}`);
    return this.http.get(url).toPromise();
  }

  public getCharityByContactId(id: string): Promise<any> {
    const url = environment.backendApi.apiUrl.concat(`/contact-charity/${id}`);
    return this.http.get(url).toPromise();
  }

  public saveCharity(charity: RegisteredCharityDto): Promise<any> {
    const url = environment.backendApi.apiUrl.concat(`/registered-charity`);
    return this.http.post(url, charity).toPromise();
  }

  public updateCharity(charity: RegisteredCharityDto): Promise<any> {
    delete charity.updatedOn;
    delete charity.createdOn;
    const url = environment.backendApi.apiUrl.concat(`/registered-charity/update`);
    return this.http.post(url, charity).toPromise();
  }

  public createCharityAccount(charityAccount: CharityAccountDto): Promise<any> {
    const url = environment.backendApi.apiUrl.concat(`/charity-account`);
    return this.http.post(url, charityAccount).toPromise();
  }

  public saveCharityUserRelation(userId: string, charityId: number) {
    const url = environment.backendApi.apiUrl.concat(`/charityuser/link`);
    return this.http.post(url, { userId, charityId }).toPromise();
  }

  public checkCharityAddress(charityAccountId: number): Promise<any> {
    const url = `${environment.backendApi.apiUrl}/charity-account/${charityAccountId}/check-address`;
    return this.http.post(url, {}).toPromise();
  }
}
