import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-organization-info-form',
  templateUrl: './organization-info-form.component.html',
  styleUrls: ['./organization-info-form.component.scss']
})
export class OrganizationInfoFormComponent implements OnInit {
  @Input() public form: FormGroup;

  constructor() { }

  static formModel(formValues = null) {
    return new FormGroup({
      displayName: new FormControl(formValues ? formValues.displayName : null, Validators.required),
      email: new FormControl(formValues ? formValues.email : null, Validators.required),
      phone: new FormControl(formValues ? formValues.phone : null, Validators.required),
      mission: new FormControl(formValues ? formValues.mission : null, Validators.required),
    });
  }

  ngOnInit() {
  }

}
