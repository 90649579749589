import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CharityService } from '../../core/services/charity.service';
import { MatCheckboxChange } from '@angular/material';

@Component({
  selector: 'app-charity-selection-form',
  templateUrl: './charity-selection-form.component.html',
  styleUrls: ['./charity-selection-form.component.scss']
})
export class CharitySelectionFormComponent implements OnInit {

  @Input() public form: FormGroup;


  public taxIdInput = '';
  public loading = false;
  public nullResponse = false;
  public charity;

  constructor(
    private charityService: CharityService,
  ) { }

  static formModel(formValues = null) {
    return new FormGroup({
      selectedCharity: new FormControl(formValues ? formValues.selectedCharity : null, Validators.required),
    });
  }

  ngOnInit() {
  }

  async searchByTaxId() {
    this.taxIdInput = this.taxIdInput.trim();
    if (this.taxIdInput.length === 0) { return; }

    try {
      this.loading = true;
      this.charity = await this.charityService.getCharityByTaxId(this.taxIdInput);
      this.nullResponse = false;
    } catch (error) {
      console.error(error);
      if (error.status === 404) {
        this.nullResponse = true;
      } else {
        this.nullResponse = false;
      }
    } finally {
      this.loading = false;
    }

  }

  async handleCharitySelectionEvent(event: MatCheckboxChange) {
    if (event.checked) {
      this.form.controls.selectedCharity.setValue(this.charity);
    } else {
      this.form.controls.selectedCharity.setValue(null);
    }
  }

}
