import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ContactDto } from '../../../../../core/dto/contact';
import { FormGroup } from '@angular/forms';
import { ContactFormComponent } from '../contact-form/contact-form.component';

@Component({
  selector: 'app-contact-modal',
  templateUrl: './contact-modal.component.html',
  styleUrls: ['./contact-modal.component.scss']
})
export class ContactModalComponent implements OnInit {

  public form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ContactModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: ContactDto
  ) { }

  ngOnInit() {
    this.form = ContactFormComponent.formModel(this.data);
  }

  onCancel(): void {
    this.dialogRef.close();
  }

}
