import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from '../../core/services/auth.service';
import { CharityService } from 'src/app/core/services/charity.service';
// import { RegisteredCharityDto } from '../../../../../core/dto/registered-charity';
// import { AlertService } from '../../core/services/';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss', '../shared.scss']
})
export class LoginFormComponent implements OnInit {
  public form: FormGroup;
  // public userType = environment.userType;

  constructor(
    // private alertService: AlertService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private router: Router,
    private charityService: CharityService
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      email: ['', Validators.email],
      password: ['', Validators.required]
    });
  }

  public signIn(): void {
    const email = this.form.value.email;
    const password = this.form.value.password;
    // let charity: RegisteredCharityDto;

    this.authService.signIn(email, password)
      .then(() => {
        this.router.navigate([`/charity`]);
      })
      .catch(error => {
        alert(error);

      });
  }

  forgotPassword(): void {
    this.router.navigate(['/forgot-password']);
  }

}
