import { Component, OnInit, Inject, Optional, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';



@Component({
  selector: 'app-mission-form',
  templateUrl: './mission-form.component.html',
  styleUrls: ['./mission-form.component.scss']
})
export class MissionFormComponent implements OnInit {
  @Input() public form: FormGroup;

  constructor(
  ) { }

  static formModel(formValues = null) {
    return new FormGroup({
      mission: new FormControl(formValues ? formValues : null, Validators.compose([Validators.maxLength(206), Validators.required])),
    });
  }

  ngOnInit() {
  }

}
