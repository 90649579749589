import { Component, OnInit, Inject, Optional, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { RegisteredCharityDto } from '../../../../../core/dto/registered-charity';
import { CharityFormComponent } from '../charity-form/charity-form.component';

@Component({
  selector: 'app-charity-modal',
  templateUrl: './charity-modal.component.html',
  styleUrls: ['./charity-modal.component.scss']
})
export class CharityModalComponent implements OnInit {
  public form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<CharityModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: RegisteredCharityDto
  ) { }

  ngOnInit() {
    this.form = CharityFormComponent.formModel(this.data);
  }

  onCancel(): void {
    this.dialogRef.close();
  }

}
