import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { map, switchMap, filter } from 'rxjs/operators';
import { User as FirebaseUser } from 'firebase';
import { UserDto } from '../../../../../core/dto/user';
import { UserService } from './user.service';
import { UserType } from '../../../../../core/enums/user-type';
import { ContactDto } from '../../../../../core/dto/contact';
import { ContactModalComponent } from 'src/app/shared/contact-modal/contact-modal.component';
import { ContactService } from './contact.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // tslint:disable-next-line:variable-name
  private _authState = new BehaviorSubject<FirebaseUser>(null);
  public readonly authState = this._authState.asObservable();

  // tslint:disable-next-line:variable-name
  private _initialized = new BehaviorSubject<boolean>(false);
  public readonly initialized = this._initialized.asObservable()
    .pipe(filter(initializedState => !!initializedState));

  public redirectUrl: string = null;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private userService: UserService,
    private contactService: ContactService
  ) {
    this.afAuth.authState
      .pipe(
        map((user: FirebaseUser) => {
          this._authState.next(user);
          return user;
        }),
        switchMap((user: FirebaseUser) => {
          console.log('SETTING USER', user);
          return user ? this.userService.getUserById(user.uid) : Promise.resolve(null);
        })
      )
      .subscribe((user: UserDto) => {
        this.userService.setUser(user);
        this._initialized.next(true);
      });

  }

  // Returns true if the user is logged in
  get authenticated(): boolean {
    return this._authState.getValue() !== null;
  }

  // Returns current authUser data
  get currentUserAuthState(): any {
    return this.authenticated ? this._authState.getValue() : null;
  }

  // Returns authState Observable
  get currentAuthStateObservable(): any {
    return this.afAuth.authState;
  }

  // Returns current user UID
  get uid(): string {
    return this.authenticated ? this._authState.getValue().uid : null;
  }

  get currentUser() {
    return this.userService.currentUser;
  }

  // Returns current user auth token
  public async getAuthorizationToken() {
    if (this.authenticated) {
      const token = await this._authState.getValue().getIdToken();
      return token;
    }
    return [];
  }

  // Sign in using email, password
  public signIn(email: string, password: string): Promise<any> {
    this._initialized.next(false);
    return this.afAuth.auth.signInWithEmailAndPassword(email, password);
  }

  // Manually sign out within settings
  public signOut() {
    return this.afAuth.auth.signOut();
  }

  // Register for a new account
  public async signUp(email: string, password: string, firstName: string, lastName: string, phone: string, position: string) {
    this._initialized.next(false);
    const newAuthUserInfo = await this.afAuth.auth.createUserWithEmailAndPassword(email, password);
    const userId = newAuthUserInfo.user.uid;
    const firebaseUser = {
      email,
      firstName,
      lastName,
      id: userId,
      type: UserType.Charity
    };
    const charityContact: Partial<ContactDto> = {
      firstName, lastName, phone, email, position
    };
    let firebaseResponse;

    try {
      firebaseResponse = await this.createUser(firebaseUser);
      await this.contactService.saveContact(charityContact);

    } catch (er) {
     console.log(er);
    }

    return firebaseResponse;
  }

  // Given a UserDocument and a user id, create a new user
  // @param user User document to store in the users collection
  public async  createUser(user: UserDto) {
    return await this.userService.saveUser(user)
      .then(async () => {
        const result = await this.userService.getUserById(user.id);
        this.userService.setUser(result);
      });
  }

  // reset forgotten password via email
  public resetPassword(email: string) {
    return this.afAuth.auth.sendPasswordResetEmail(email);
  }

  public firestoreSanitize(object: any): object {
    return Object.assign({}, object);
  }
}
