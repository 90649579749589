export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyBF5D8tODOynQEB40P-Kl3u4Nezr8SIkCY',
    authDomain: 'my-project-1508076955798.firebaseapp.com',
    databaseURL: 'https://my-project-1508076955798.firebaseio.com',
    projectId: 'my-project-1508076955798',
    storageBucket: 'my-project-1508076955798.appspot.com',
    messagingSenderId: '411353419573'
  },
  backendApi: {
    apiUrl: 'https://my-project-1508076955798.appspot-preview.com/api/v1',
    apiToken: 'SuperSecretERADevTokenPL7dM73J8vHVNY3',
  },
  plaid: {
    env: 'development',
    clientId: '5cbbabd85f6405001217f344',
    clientSecret: 'b38c221b8e447652f5bfcb756dd3fb',
    publicKey: '1c7baf8abce108f2b4e125591ea374'
  }
};
