import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';
import { LoginGuard } from './core/guards/login.guard';

const routes: Routes = [
  {
    path: 'session',
    loadChildren: './session/session.module#SessionModule'
  },
  {
    path: 'login',
    canActivate: [LoginGuard],
    loadChildren: './login/login.module#LoginModule'
  },
  {
    path: 'forgot-password',
    loadChildren: './forgot-password/forgot-password.module#ForgotPasswordModule'
  },
  {
    path: 'charity',
    canActivate: [AuthGuard],
    loadChildren: './charity/charity.module#CharityModule'
  },
  {
    path: 'bank',
    canActivate: [AuthGuard],
    loadChildren: './bank/bank.module#BankModule'
  },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    loadChildren: './admin/admin.module#AdminModule'
  },
  {
    path: '',
    redirectTo: 'charity',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'login',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
