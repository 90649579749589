import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material';
import { MaterialModule } from './material/material.module';
import { CharityInfoCardComponent } from './charity-info-card/charity-info-card.component';
import { BankInfoCardComponent } from './bank-info-card/bank-info-card.component';
import { ContactInfoCardComponent } from './contact-info-card/contact-info-card.component';
import { CharityFormComponent } from './charity-form/charity-form.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MissionFormComponent } from './mission-form/mission-form.component';
import { ReportsCardComponent } from './reports-card/reports-card.component';
import { DonationActivityComponent } from './donation-activity/donation-activity.component';
import { SignUpFormComponent } from './sign-up-form/sign-up-form.component';
import { ContactModalComponent } from './contact-modal/contact-modal.component';
import { CharityModalComponent } from './charity-modal/charity-modal.component';
import { OrganizationInfoFormComponent } from './organization-info-form/organization-info-form.component';
import { MissionModalComponent } from './mission-modal/mission-modal.component';
import { CharitySelectionFormComponent } from './charity-selection-form/charity-selection-form.component';
import { CharityDisplayCardComponent } from './charity-display-card/charity-display-card.component';
import { TosModalComponent } from './tos-modal/tos-modal.component';
import { TextMaskModule } from 'angular2-text-mask';

@NgModule({
  declarations: [
    CharityInfoCardComponent,
    BankInfoCardComponent,
    ContactInfoCardComponent,
    CharityFormComponent,
    ContactFormComponent,
    LoginFormComponent,
    MissionFormComponent,
    ReportsCardComponent,
    DonationActivityComponent,
    SignUpFormComponent,
    ContactModalComponent,
    CharityModalComponent,
    OrganizationInfoFormComponent,
    MissionModalComponent,
    CharitySelectionFormComponent,
    CharityDisplayCardComponent,
    TosModalComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatDialogModule,
    TextMaskModule,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    CharityInfoCardComponent,
    BankInfoCardComponent,
    ContactInfoCardComponent,
    ContactFormComponent,
    CharityFormComponent,
    ReportsCardComponent,
    DonationActivityComponent,
    LoginFormComponent,
    SignUpFormComponent,
    MissionFormComponent,
    MissionModalComponent,
    CharitySelectionFormComponent,
    CharityDisplayCardComponent,
    TextMaskModule,
  ],
  entryComponents: [
    MissionModalComponent,
    ContactModalComponent,
    CharityModalComponent,
    TosModalComponent
  ]
})
export class SharedModule { }
