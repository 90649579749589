import { Component, OnInit, Input } from '@angular/core';
import { RegisteredCharityDto } from '../../../../../core/dto/registered-charity';

@Component({
  selector: 'app-charity-display-card',
  templateUrl: './charity-display-card.component.html',
  styleUrls: ['./charity-display-card.component.scss', '../../app.component.scss']
})
export class CharityDisplayCardComponent implements OnInit {
  @Input() charity: RegisteredCharityDto;

  constructor() { }

  ngOnInit() {}

  get name() {
    if (!this.charity) { return ''; }
    return this.charity.name || this.charity.charity && this.charity.charity.name || '';
  }

  get phone() {
    if (!this.charity) { return ''; }
    return this.charity.phone || this.charity.charity && this.charity.charity.phone || '';
  }

  get email() {
    if (!this.charity) { return ''; }
    return this.charity.email || this.charity.charity && this.charity.charity.email || '';
  }

  get url() {
    if (!this.charity) { return ''; }
    return this.charity.url || this.charity.charity && this.charity.charity.url || '';
  }

  get street1() {
    if (!this.charity) { return ''; }
    return this.charity.street1 || this.charity.charity && this.charity.charity.street1 || '';
  }

  get street2() {
    if (!this.charity) { return ''; }
    return this.charity.street2 || this.charity.charity && this.charity.charity.street2 || '';
  }

  get city() {
    if (!this.charity) { return ''; }
    return this.charity.city || this.charity.charity && this.charity.charity.city || '';
  }

  get state() {
    if (!this.charity) { return ''; }
    return this.charity.state || this.charity.charity && this.charity.charity.state || '';
  }

  get zip() {
    if (!this.charity) { return ''; }
    return this.charity.zip || this.charity.charity && this.charity.charity.zip || '';
  }


}
