import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PlaidSuccessMetadata } from 'ngx-plaid-link/lib/interfaces';

import { UserDto } from '../../../../../core/dto/user';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  // tslint:disable-next-line:variable-name
  private _user = new BehaviorSubject<UserDto>(null);
  public readonly user = this._user.asObservable();

  constructor(private http: HttpClient) { }

  get currentUser() {
    return this._user.getValue();
  }

  public saveUser(user: UserDto): Promise<any> {
    console.log('Saving user', user);
    const url = environment.backendApi.apiUrl.concat('/user');
    return this.http.post(url, user).toPromise();
  }

  public updateUser(id: string, firstName: string, lastName: string, email: string): Promise<any> {
    const user = { id, firstName, lastName, email };
    const url = environment.backendApi.apiUrl.concat(`/user/${id}`);
    return this.http.post(url, user).toPromise();
  }

  public async setUser(user) {
    return this._user.next(user);
  }

  public getUserById(id): Promise<any> {
    console.log('Getting logged-in user');
    const url = environment.backendApi.apiUrl.concat(`/user/${id}`);
    return this.http.get(url).toPromise();
  }

  public emailIsAvailable(emailAddress: string): Promise<any> {
    console.log('Checking if email address is available');
    const url = environment.backendApi.apiUrl.concat(`/email/${emailAddress}`);
    return this.http.get(url).toPromise();
  }

  public userHasAccount(userId: string): Promise<any> {
    console.log('Checking if user has completed plaid process');
    const url = environment.backendApi.apiUrl.concat(`/user/has-account/${userId}`);
    return this.http.get(url).toPromise();
  }

  public linkUserAccount(plaidMetadata: PlaidSuccessMetadata, userId: string, creditUnionId: number): Promise<any> {
    console.log('Linking user account to plaid', plaidMetadata);
    const body = { plaidMetadata, userId, creditUnionId };
    const url = environment.backendApi.apiUrl.concat(`/account/link`);
    return this.http.post(url, body).toPromise();
  }

  public async refreshUser() {
    const result = await this.getUserById(this.currentUser.id);
    this.setUser(result);
  }
}
