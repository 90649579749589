import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ContactDto } from '../../../../../core/dto/contact';
import { RegisteredCharityDto } from '../../../../../core/dto/registered-charity';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(
    private http: HttpClient,
  ) { }

  public getContactByCharityId(id: number): Promise<any> {
    const url = environment.backendApi.apiUrl.concat(`/charity-contact/${id}`);
    return this.http.get(url).toPromise();
  }

  public updateContact(contact: ContactDto): Promise<RegisteredCharityDto> {
    delete contact.updatedOn;
    delete contact.createdOn;
    const url = environment.backendApi.apiUrl.concat(`/contact/update`);
    return this.http.post<RegisteredCharityDto>(url, contact).toPromise();
  }

  public saveContact(contact: Partial<ContactDto>): Promise<RegisteredCharityDto> {
    const url = environment.backendApi.apiUrl.concat(`/contact`);
    return this.http.post<RegisteredCharityDto>(url, contact).toPromise();
  }

  public linkContactToCharity(email: string, charityId: number) {
    const url = environment.backendApi.apiUrl.concat(`/contact/link`);
    return this.http.post<ContactDto>(url, { email, charityId }).toPromise();
  }
}
