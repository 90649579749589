import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {
  @Input() public form: FormGroup;

  public telMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  static formModel(formValues = null) {
    return new FormGroup({
      firstName: new FormControl(formValues ? formValues.firstName : null, Validators.required),
      lastName: new FormControl(formValues ? formValues.lastName : null, Validators.required),
      email: new FormControl(formValues ? formValues.email : null, [Validators.required, Validators.email]),
      // tslint:disable-next-line:max-line-length
      phone: new FormControl(formValues ? formValues.phone : null, Validators.compose([Validators.minLength(14), Validators.maxLength(14), Validators.required, Validators.pattern('^((?!_).)*$')])),
      position: new FormControl(formValues ? formValues.position : null, Validators.required),
    });
  }

  ngOnInit() {
  }

}

