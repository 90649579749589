import { Component, Input, OnInit } from '@angular/core';
import { NgxPlaidLinkService } from 'ngx-plaid-link';
import { PlaidConfig } from 'ngx-plaid-link/lib/interfaces';
import { PlaidLinkHandler } from 'ngx-plaid-link/lib/ngx-plaid-link-handler';
import { environment } from '../../../environments/environment';

import { CharityService } from '../../core/services/charity.service';
import { CharityAccountDto } from '../../../../../core/dto/charity-account';
import { RegisteredCharityDto } from '../../../../../core/dto/registered-charity';


@Component({
  selector: 'app-bank-info-card',
  templateUrl: './bank-info-card.component.html',
  styleUrls: ['./bank-info-card.component.scss', '../../app.component.scss']
})
export class BankInfoCardComponent implements OnInit {
  @Input() charity: RegisteredCharityDto;
  private plaidLinkHandler: PlaidLinkHandler;
  public primaryAccount: CharityAccountDto;

  private config: PlaidConfig = {
    env: environment.plaid.env,
    product: ['auth'],
    key: environment.plaid.publicKey,
    onSuccess: this.onPlaidSuccess,
    onExit: this.onPlaidExit,
    clientName: 'NetGiver'
  };

  constructor(
    private plaidLinkService: NgxPlaidLinkService,
    private charityService: CharityService
  ) { }

  ngOnInit() {
    console.log(this.charity);
    this.primaryAccount = this.charity.charityAccounts[0];
  }

  openPlaidDialog(): void {
    this.plaidLinkService.createPlaid(Object.assign({}, this.config, {
      onSuccess: (token, metadata) => this.onPlaidSuccess(token, metadata),
      onExit: (error, metadata) => this.onPlaidExit(error, metadata)
    })).then((handler: PlaidLinkHandler) => {
      this.plaidLinkHandler = handler;
      this.openPlaid();
    });
  }

  openPlaid() {
    this.plaidLinkHandler.open();
  }

  exitPlaid() {
    // Close plaid
    this.plaidLinkHandler.exit();
  }

  onPlaidSuccess(token, metadata) {
    const account: CharityAccountDto = {
      publicToken: token,
      registeredCharity: { id: this.charity.id},
      selectedAccountId: metadata.account.id,
      selectedAccountName: metadata.account.name,
      institutionName: metadata.institution.name
    };
    console.log('Account linked', account);
    this.primaryAccount = account;
    this.charityService.createCharityAccount(account);
  }

  onPlaidExit(error, metadata) {
    // Plaid closed with error
    console.log('EXIT', error);
  }

}
