import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material';

import { RegisteredCharityDto } from '../../../../../core/dto/registered-charity';
import { CharityService } from '../../core/services/charity.service';
import { CharityModalComponent } from '../charity-modal/charity-modal.component';

@Component({
  selector: 'app-charity-info-card',
  templateUrl: './charity-info-card.component.html',
  styleUrls: ['./charity-info-card.component.scss', '../../app.component.scss']
})
export class CharityInfoCardComponent implements OnInit {
  @Input() charity: RegisteredCharityDto;

  constructor(
    public dialog: MatDialog,
    private charityService: CharityService
    ) { }

  ngOnInit() {
  }

  openEditModal() {
    const dialogRef = this.dialog.open(CharityModalComponent, {
      width: '550px',
      data: this.charity
    });

    dialogRef.afterClosed().subscribe(result => {
      this.charity = Object.assign(this.charity, result.value);
      console.log('CHARITY UPDATED', this.charity);
      this.charityService.updateCharity(this.charity);
    });
  }

  get name() {
    return this.charity.name || this.charity.charity && this.charity.charity.name || '';
  }

  get phone() {
    return this.charity.phone || this.charity.charity && this.charity.charity.phone || '';
  }

  get email() {
    return this.charity.email || this.charity.charity && this.charity.charity.email || '';
  }

  get url() {
    return this.charity.url || this.charity.charity && this.charity.charity.url || '';
  }

  get street1() {
    return this.charity.street1 || this.charity.charity && this.charity.charity.street1 || '';
  }

  get street2() {
    return this.charity.street2 || this.charity.charity && this.charity.charity.street2 || '';
  }

  get city() {
    return this.charity.city || this.charity.charity && this.charity.charity.city || '';
  }

  get state() {
    return this.charity.state || this.charity.charity && this.charity.charity.state || '';
  }

  get zip() {
    return this.charity.zip || this.charity.charity && this.charity.charity.zip || '';
  }

}
