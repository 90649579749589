import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from '@angular/common/http';

import { AuthService } from '../services/auth.service';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (this.auth.authenticated) {
      return from(this.auth.getAuthorizationToken())
        .pipe(switchMap(authToken => {
          if (authToken && authToken !== '') {
            // Clone the request and replace the original headers with
            // cloned headers, updated with the authorization.
            const authReq = req.clone({
              setHeaders: {
                Authorization: `Bearer ${authToken}`
              }
            });

            // send cloned request with header to the next handler.
            return next.handle(authReq);
          }
        }
        ));
    }
    console.log('NOT AUTHENTICATED, SENDING API CALL W/O AUTH HEADER');
    return next.handle(req);
  }

}
