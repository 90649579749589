import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-charity-form',
  templateUrl: './charity-form.component.html',
  styleUrls: ['./charity-form.component.scss']
})
export class CharityFormComponent {
  @Input() public form: FormGroup;

  public telMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  constructor() { }

  static formModel(formValues = null) {
    return new FormGroup({
      dbaName: new FormControl(formValues ? formValues.dbaName ? formValues.dbaName : formValues.name : null, Validators.required),
      street1: new FormControl({ value: formValues ? formValues.street1 : null, disabled: true }),
      street2: new FormControl({ value: formValues ? formValues.street2 : null, disabled: true }),
      city: new FormControl({ value: formValues ? formValues.city : null, disabled: true }),
      state: new FormControl({ value: formValues ? formValues.state : null, disabled: true }),
      zip: new FormControl({ value: formValues ? formValues.zip : null, disabled: true }),
      // tslint:disable-next-line:max-line-length
      phone: new FormControl(formValues ? formValues.phone : null, Validators.compose([Validators.minLength(14), Validators.maxLength(14), Validators.required, Validators.pattern('^((?!_).)*$')])),
      email: new FormControl(formValues ? formValues.email : null, Validators.compose([Validators.email, Validators.required])),
      url: new FormControl(formValues ? formValues.url : null),
    });
  }
}






