import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
@Component({
  selector: 'app-sign-up-form',
  templateUrl: './sign-up-form.component.html',
  styleUrls: ['./sign-up-form.component.scss', '../shared.scss']
})
export class SignUpFormComponent implements OnInit {
  @Input() public form: FormGroup;

  public telMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  static formModel() {
    const email = new FormControl('', Validators.compose([Validators.required, Validators.email]));
    const phone = new FormControl('', Validators.compose([Validators.minLength(14), Validators.maxLength(14), Validators.required, Validators.pattern('^((?!_).)*$')]));
    const firstName = new FormControl('', Validators.required);
    const lastName = new FormControl('', Validators.required);
    const position = new FormControl('', Validators.compose([Validators.required]));
    const password = new FormControl('', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(25)]));
    const confirm = new FormControl('', Validators.compose([Validators.required, CustomValidators.equalTo(password)]));

    return new FormGroup({
      email,
      phone,
      firstName,
      lastName,
      position,
      password,
      confirm,
    });
  }

  constructor() { }

  ngOnInit() {}
}
