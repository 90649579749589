import { Component, OnInit, Inject, Optional, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup } from '@angular/forms';
import { MissionFormComponent } from '../mission-form/mission-form.component';

@Component({
  selector: 'app-mission-modal',
  templateUrl: './mission-modal.component.html',
  styleUrls: ['./mission-modal.component.scss']
})
export class MissionModalComponent implements OnInit {
  public form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<MissionModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: string
  ) { }

  ngOnInit() {
    this.form = MissionFormComponent.formModel(this.data);
  }

  onCancel(): void {
    this.dialogRef.close();
  }


}
